<!-- 产品中心 -->
<template>
  <div class="business gn-box-border">
    <div class="banner pc-banner">
      <img class="img1" :src="bannerUrl1" alt="" />
      <img class="img2" :src="bannerUrl2" alt="" />
    </div>
    <div class="test">
      <div class="test_box">
        <div class="title">
          <span class="titile_text">宝秤
            <span class="titile_icon">®</span>
          </span>
          是什么？</div>
        <div class="item1">
          <div class="card">
            <div class="card_title">
              <div class="line"></div>
              <div class="text">
                <span class="titile_text">宝秤
                  <span class="titile_icon">®</span>
                </span>
              </div>
              <div class="line"></div>
            </div>
            <div class="card_text1" style="white-space: nowrap;">
              由<span class="titile_text">宝秤农资
                <span class="titile_icon">®</span>
              </span>
              APP(厂、商端)和<span class="titile_text">宝乡通
                <span class="titile_icon">®</span>
              </span>
              智能助手(用户端)组合
            </div>
            <div class="card_text2">农资行业全供应链数字化公共服务平台</div>
          </div>
          <div class="card card_2">
            <div class="card_text3">
              <div>
                  <span class="titile_text">宝秤农资
                    <span class="titile_icon">®</span>
                  </span>
              </div>
              <div class="center-left">链接了1000家农药、肥料、农资厂商</div>
            </div>
            <div class="card_text3">
              <div>
                <span class="titile_text">宝乡通
                  <span class="titile_icon">®</span>
                </span>
              </div>
              <div class="center-left">链接了广东省2000家农资门店</div>
            </div>
            <div class="card_text5">实现了从工厂到农户的数字化无缝对接服务</div>
          </div>
        </div>
        <div class="item2">
          <div class="item2_title1">系统特点</div>
          <div class="item2_title2">借互联网的势，避互联网的坑</div>
          <div class="item2_title3">
            <div class="card_text card_texts">
              <div>不做</div>
              <div>全国市场买和卖</div>
            </div>
            <div class="card_text">
              <div>专注</div>
              <div>区城市场本地服务</div>
            </div>
          </div>
          <div class="item2_title4">服务价格一对一，信息安全不泄露</div>
        </div>
      </div>
    </div>
    <div class="lineView">
      <div class="line"></div>
    </div>
    <div class="pain_point">
      <div class="pain_point_title">
        {{ painTitle }}
      </div>
      <div class="pain_point_box">
        <div class="pain_point_box_item">
          <img :src="painItem1.img" alt="" />
          <div style="width: 154px;" class="box_item1">{{ painItem1.introduce }}</div>
        </div>
        <div class="pain_point_box_item">
          <img :src="painItem2.img" alt="" />
          <div style="width: 122px;" class="box_item2">{{ painItem2.introduce }}</div>
        </div>
        <div class="pain_point_box_item">
          <img :src="painItem3.img" alt="" />
          <div class="item_wid box_item3" >{{ painItem3.introduce }}</div>
        </div>
        <div class="pain_point_box_item">
          <img :src="painItem4.img" alt="" />
          <div style="width: 200px;" class="box_item4">{{ painItem4.introduce }}</div>
        </div>
      </div>
    </div>
    <div class="main">
      <!-- 三大特色 -->
      <div class="card">
        <div>工厂→终端→用户</div>
        <div>领先的农资供应链数字智能公共服务平台</div>
      </div>
      <div class="dealers_box">
        <div class="dealers">
          <div class="dealers_left">
            <div class="title1">全国一体化管理的农资APP</div>
            <img :src="platformUrl1" alt="" />
          </div>
          <div class="dealers_center">
            <ul>
              <li>
                <div></div>
                <div></div>
              </li>
              <li>
                <div></div>
                <div></div>
              </li>
              <li>
                <div></div>
                <div></div>
              </li>
            </ul>
          </div>
          <div class="dealers_right">
            <div class="title1">厂商店营销联动</div>
            <img :src="platformUrl2" alt="" />
          </div>
        </div>
      </div>
      <div class="card card_addchengg" ref="characteristic">
        <div class="pcdisp">系统成功的秘密-宝秤农资三大特色</div>
        <div class="appdisp chengg">系统成功的秘密</div>
        <div class="appdisp">——宝秤农资三大特色</div>
      </div>
      <div class="features">
        <div class="content">
          <ul class="pc-features">
            <li>
              <img
                src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20240625/17061700143261590279"
                alt=""
              />
            </li>
            <li class="li_text">
              <p>没有信息化就没有数字化</p>
              <p>数字化的基础是系统的简单化、工具化</p>
              <div class="li_text_title">
                <span>传统电商商城模式</span>
                <div class="li_text_title_icon1"></div>
                <div class="li_text_title_icon2"></div>
                <span>渠道管理+商城模式</span>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>一键搭建全国一体化的渠道销售体系</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>渠道管理的工具专属性与商城开放属性相结合</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>私密商城/协议价VS旗舰店/市场价，内外有别。</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>广告、促销直达终端</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>简单、人人可用</p>
                </div>
              </div>
            </li>

            <li class="li_text" style="padding: 74px 20px 74px 40px">
              <p>数字化=</p>
              <p>互联网的高效+进销存节点控制+数据录入自动化</p>
              <div class="li_text_title">
                <span>传统产品代理制</span>
                <div class="li_text_title_icon1"></div>
                <div class="li_text_title_icon2"></div>
                <span>云仓+本地经销配送</span>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>虚拟云仓为农资门店提供更多产品选择，不占用周转资金</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>区域运营服务，一省千镇，一镇一小批发店</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>一市（地级）一前置仓，极致配送</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>
                    农资门店线下线上一体化，区别于买全国卖全国电商模式，网店只服务本地乡村社区农业生产企业，配送半径50公里
                  </p>
                </div>
              </div>
            </li>
            <li>
              <img
                src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20240325/17061698092844420943"
                alt=""
              />
            </li>

            <li>
              <img
                src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20241225/17061703308026657358"
                alt=""
              />
            </li>
            <li class="li_text" style="padding: 54px 40px 54px 60px">
              <p>专业的工作傻瓜化，</p>
              <p>复杂的工作简单化，</p>
              <p>多人协同的工作一人一键完成</p>
              <div class="li_text_title_p">
                <div>
                  <p>——供应链上游：</p>
                </div>
                <div>
                  <p>宝秤APP</p>
                  <p>渠道管理工具</p>
                </div>
              </div>
              <div class="li_text_title_p">
                <div>
                  <p>——供应链下游：</p>
                </div>
                <div>
                  <p>宝乡通电子智能助手</p>
                  <p>本地社群化小程序网店</p>
                </div>
              </div>
              <div class="item_p">
                <div class="item_p_left">
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>企业管理人员：</p>
                      <p>宝秤APP、PC端</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>农户农企：</p>
                      <p>宝乡通小程序商城</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p style="white-space: nowrap;">农资门店：</p>
                      <div>
                        <p style="white-space: nowrap;">宝乡通电子智能助手，</p>
                        <p>线下线上一体化管理</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item_p_right">
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>植保服务：</p>
                      <p>AI智能互动</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>售后台账：</p>
                      <p>保密后台</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>广告促销：</p>
                      <div>
                        <p>互动大屏，</p>
                        <p>优惠券直达</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <ul class="mobile-features">
            <li>
              <img
                src="@/assets/images/newbazaarIntroduce/features_1.png"
                alt=""
              />
            </li>
            <li class="li_text">
              <p>没有信息化就没有数字化</p>
              <p>数字化的基础是系统的简单化、工具化</p>
              <div class="li_text_title">
                <span>传统电商商城模式</span>
                <div class="li_text_title_icon1"></div>
                <div class="li_text_title_icon2"></div>
                <span>渠道管理+商城模式</span>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>粗放经营，假冒伪劣产品充斥，无进销存管理</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>渠道管理的工具专属性与商城开放属性相结合</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>私密商城/协议价VS旗舰店/市场价，内外有别。</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>广告、促销直达终端</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>简单、人人可用</p>
                </div>
              </div>
            </li>

            <li>
              <img
                src="@/assets/images/newbazaarIntroduce/features_2.png"
                alt=""
              />
            </li>
            <li class="li_text" style="padding: 20px">
              <p>数字化=</p>
              <p>互联网的高效+进销存节点控制+数据录入自动化</p>
              <div class="li_text_title">
                <span>传统产品代理制</span>
                <div class="li_text_title_icon1"></div>
                <div class="li_text_title_icon2"></div>
                <span>云仓+本地经销配送</span>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>虚拟云仓为农资门店提供更多产品选择，不占用周转资金</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>区域运营服务，一省千镇，一镇一小批发店</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>一市（地级）一前置仓，极致配送</p>
                </div>
              </div>
              <div class="item">
                <div class="icon"></div>
                <div class="text">
                  <p>
                    农资门店线下线上一体化，区别于买全国卖全国电商模式，网店只服务本地乡村社区农业生产企业，配送半径50公里
                  </p>
                </div>
              </div>
            </li>

            <li>
              <img
                src="@/assets/images/newbazaarIntroduce/features_3.png"
                alt=""
              />
            </li>
            <li class="li_text" style="padding: 20px">
              <p>专业的工作傻瓜化，</p>
              <p>复杂的工作简单化，</p>
              <p>多人协同的工作一人一键完成</p>
              <div class="li_text_title_p">
                <div>
                  <p>——供应链上游：</p>
                </div>
                <div>
                  <p>宝秤APP</p>
                  <p>渠道管理工具</p>
                </div>
              </div>
              <div class="li_text_title_p">
                <div>
                  <p>——供应链下游：</p>
                </div>
                <div>
                  <p>宝乡通电子智能助手</p>
                  <p>本地社群化小程序网店</p>
                </div>
              </div>
              <div class="item_p">
                <div class="item_p_left">
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>企业管理人员：</p>
                      <p>宝秤APP、PC端</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>农户农企：</p>
                      <p>宝乡通小程序商城</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>农资门店：</p>
                      <p>宝乡通电子智能助手，线下线上一体化管理</p>
                    </div>
                  </div>
                </div>
                <div class="item_p_right">
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>植保服务：</p>
                      <p>AI智能互动</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>售后台账：</p>
                      <p>保密后台</p>
                    </div>
                  </div>
                  <div class="item">
                    <div class="icon"></div>
                    <div class="text">
                      <p>广告促销：</p>
                      <p>互动大屏，优惠券直达</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 使用说明 -->
      <div class="card" ref="instructions">
        <div>使用说明</div>
      </div>
      <div class="instructions">
        <div class="content">
          <ul>
            <li
              v-for="(item, index) in instructions"
              :key="index"
              @click="goVideoShow(item)"
            >
              <div class="video rela">
                <img :src="item.instructionImgs" class="gn-full" />
                <div class="icon center">
                  <img src="@/assets/images/newbazaarIntroduce/video.png" />
                </div>
                <div class="bg_img"></div>
              </div>
              <div class="info">
                <div class="title">{{ item.name }}</div>
                <div class="time">{{ item.create_time }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPublishListByType } from '@/api/officialWebsite'

export default {
  name: 'business',
  data () {
    return {
      tabKey: '0',
      tabs: [
        {
          title: '市场痛点和农资平台',
          list: [],
          loadmore: false
        },
        {
          title: '系统概述',
          list: [],
          loadmore: false
        },
        {
          title: '三大特色',
          list: [],
          loadmore: false
        },
        {
          title: '使用说明',
          list: [],
          loadmore: false
        }
      ],
      instructions: [],
      instructionsVideoCover: [
        require('@/assets/images/newbazaarIntroduce/instructions1.png'),
        require('@/assets/images/newbazaarIntroduce/instructions2.png'),
        require('@/assets/images/newbazaarIntroduce/instructions3.png'),
        require('@/assets/images/newbazaarIntroduce/instructions4.png'),
        require('@/assets/images/newbazaarIntroduce/instructions5.png'),
        require('@/assets/images/newbazaarIntroduce/instructions6.png')
      ],
      bannerUrl1: '',
      bannerUrl2: '',

      painTitle: '',
      painItem1: {},
      painItem2: {},
      painItem3: {},
      painItem4: {},
      platformUrl1: '',
      platformUrl2: ''
    }
  },
  created () {
    this.getData()
  },
  mounted () {
    this.initData()
    this.watchRouteName()
    const type = this.$route.query.type || ''
    if (type) {
      if (type === 'characteristic') {
        setTimeout(() => {
          this.mappingPlatformType(1)
        }, 20)
      } else if (type === 'instructions') {
        setTimeout(() => {
          this.mappingPlatformType(2)
        }, 20)
      }
    }
  },
  watch: {
    // 监听,当路由发生变化的时候执行
    $route: {
      handler: function (val, oldVal) {
        const type = val.query.type ? val.query.type : val.name
        if (type) {
          if (type === 'characteristic') {
            setTimeout(() => {
              this.mappingPlatformType(1)
            }, 20)
          } else if (type === 'instructions') {
            setTimeout(() => {
              this.mappingPlatformType(2)
            }, 20)
          }
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    watchRouteName () {
      this.$watch('$route.meta.title', (title) => {
        console.log(title, 'title标题')
        // 当前导航菜单切换滚动视图
        this.toggleNavMenuScrollView(title)
      })
    },
    toggleNavMenuScrollView (menuName) {
      window.scrollTo(0, 0)
      setTimeout(() => {
        if (menuName === '三大特色') {
          this.$refs.characteristic.scrollIntoView({ behavior: 'smooth' })
        } else if (menuName === '使用说明') {
          this.$refs.instructions.scrollIntoView({ behavior: 'smooth' })
        }
      }, 30)
    },
    initData () {
      const { title } = this.$route.meta
      setTimeout(() => {
        // 从其它页面跳转过来滚动视图
        this.mappingMetaTitle(title)
      }, 100)
    },
    async getData () {
      const res = await getPublishListByType({ publish_type: ['7', '10'] })
      const arr = res['10']
      arr.forEach((item) => {
        if (item.name === 'banner图') {
          this.bannerUrl2 = item.img
        }
        if (item.name === 'banner图移动端') {
          this.bannerUrl1 = item.img
        }
        if (item.name === '农资行业痛点') {
          this.painTitle = item.introduce
        }
        if (item.name === '农资行业痛点1') {
          this.painItem1 = item
        }
        if (item.name === '农资行业痛点2') {
          this.painItem2 = item
        }
        if (item.name === '农资行业痛点3') {
          this.painItem3 = item
        }
        if (item.name === '农资行业痛点4') {
          this.painItem4 = item
        }
        if (item.name === '全国一体化管理的农资APP') {
          this.platformUrl1 = item.img
        }
        if (item.name === '厂商店营销联动') {
          this.platformUrl2 = item.img
        }
      })
      this.instructions = (res[7] || []).map((item, index) => {
        return {
          ...item,
          instructionImgs: this.instructionsVideoCover[index]
        }
      })
    },
    handleCheck (i) {
      console.log(i)
      this.tabKey = i
      // 点击 tab 滚动视图
      this.mappingPlatformType(i)
    },
    mappingMetaTitle (title) {
      if (title) {
        const fn = {
          三大特色: () => this.scrollView('characteristic'), // 三大特色
          使用说明: () => this.scrollView('instructions') // 使用说明
        }[title]

        fn && fn()
      }
    },
    mappingPlatformType (i) {
      const fn = {
        1: () => this.scrollView('characteristic'), // 三大特色
        2: () => this.scrollView('instructions') // 使用说明
      }[i]
      fn && fn()
    },
    scrollView (tabRef) {
      window.scrollTo(0, 0)
      setTimeout(() => {
        this.$refs[tabRef].scrollIntoView({ behavior: 'smooth' })
      }, 35)
    },
    goVideoShow (item) {
      if (this.$isMobile) {
        this.$router.push({
          name: 'mobileVideo',
          query: {
            type: '4',
            videoUrl: item.file,
            name: item.name,
            cover: item.instructionImgs
          }
        })
      } else {
        this.$router.push({
          name: 'video',
          query: {
            type: '4', // 3:使用说明
            videoUrl: item.file,
            name: item.name
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
// 隐藏移动端的东西
.mobile-img {
  display: none;
}
.mobile-features {
  display: none !important;
}

.business {
  margin-top: 65px;
  box-sizing: border-box;
  color: #333333;
  .test {
  padding: 65px 0px 75px;
  width: 100%;
  background-color: #f0f0f0;
  .test_box {
    margin: 0 auto;
    width: 1440px;
    color: #333333;
    .titile_text{
        font-size: 48px;
        position: relative;
        padding: 0 10px;
        font-weight: 700;
        text-align: center;
      }
      .titile_icon {
        font-size: 20px;
        position: absolute;
        top: 0px;
        right: 2px;
      }
    .title {
      margin-bottom: 50px;
      text-align: center;
      font-size: 48px;
      font-weight: 700;
    .titile_text{
      padding-left: 0;

    }
    }
    .item1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .card {
        padding: 36px 50px 33px;
        width: 701px;
        height: 194px;
        margin: 0;
        background: #ffffff;
        .card_title {
          display: flex;
          align-items: center;
          justify-content: center;
          .line {
            flex: 1;
            height: 1px;
            background-color: #cccccc;
          }
          .text {
            margin: 0px 20px;
            width: 77px;
            height: 29px;
            background: #2d76d1;
            font-size: 22px;
            font-weight: 700;
            color: #ffffff;
            text-align: center;
            line-height: 29px;
            .titile_text{
              font-size: 22px;
              padding-left: 0;
            }
            .titile_icon{
              font-size: 16px;
              right: -5px;
              top:-5px;
            }
          }
        }
        .card_text1 {
          margin: 20px 0px 20px;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          color: #333333;
          .titile_text{
           font-size: 20px;
           padding-left: 0;
          }
          .titile_icon{
            font-size: 16px;
          }
        }
        .card_text2 {
          font-size: 26px;
          font-weight: 700;
          text-align: center;
          color: #2d76d1;
        }
        .card_text3 {
          margin-bottom: 21px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .titile_text{
            font-size: 20px;
            padding-left: 0;
          }
          .titile_icon{
            font-size: 14px;
            top: -5px;
            right: -5px;
          }
          div:nth-child(1) {
            width: 108px;
            height: 29px;
            background: #2d76d1;
            font-size: 20px;
            font-weight: 700;
            color: #ffffff;
            text-align: center;
            line-height: 29px;
          }
          div:nth-child(2) {
            margin-left: 10px;
            flex: 1;
            font-size: 20px;
            font-weight: 700;
            color: #333333;
          }
        }
        .card_text5 {
          font-size: 26px;
          font-weight: 700;
          text-align: center;
          color: #2d76d1;
        }
      }
    }
    .item2 {
      padding: 36px 50px 54px;
      margin-top: 37px;
      width: 100%;
      height: 315px;
      background: #ffffff;
      .item2_title1 {
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        color: #333333;
      }
      .item2_title2 {
        margin: 5px 0px 25px;
        font-size: 24px;
        font-weight: normal;
        text-align: center;
      }
      .item2_title3 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .card_texts {
          margin-right: 102px;
        }
        .card_text {
          display: flex;
          align-items: center;
          justify-content: center;
          div:nth-child(1) {
            width: 89px;
            height: 45px;
            background: #2d76d1;
            font-size: 36px;
            font-weight: 700;
            text-align: center;
            line-height: 45px;
            color: #ffffff;
          }
          div:nth-child(2) {
            margin-left: 10px;
            flex: 1;
            font-size: 42px;
            font-weight: 700;
            text-align: justifyLeft;
            color: #2d76d1;
          }
        }
      }
      .item2_title4 {
        margin-top: 17px;
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        color: #333333;
      }
    }
  }
}

  .banner {
    margin: 0 auto;
    width: 100%;
    background-size: cover;
    img {
      width: 100%;
      height: auto;
    }
    .img1 {
      display: none;
    }
    .img2 {
      display: block;
    }
  }
  .card {
    margin: 125px 0px 79px;
    div {
      font-size: 48px;
      font-weight: 700;
      text-align: center;
      color: #333333;
    }
  }
  .center-left{
    text-align: left !important;
  }
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #333334;
  }
  .lineView{
    width: 100%;
    height: 2px;
    background-color:#f0f0f0;
    .line{
      margin: 0 auto;
      width: 1440px;
      height: 2px;
      background-color:#cccccc;

    }
  }
  .pain_point {
    padding: 52px 0px 76px;
    width: 100%;
    background: #f0f0f0;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    color: #333333;
    .pain_point_title {
      margin-bottom: 58px;
    }
    .pain_point_box {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1440px;
      .pain_point_box_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 345px;
        height: 285px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        img {
          margin-top: 50px;
          margin-bottom: 21px;
          width: 64px;
          height: auto;
        }
        div {
          width: 200px;
          font-size: 30px;
          font-weight: 400;
          text-align: center;
          color: #333333;
        }
        .item_wid{
          width: 215px;
        }
      }
    }
  }
  .dealers_box {
    width: 1440px;
    margin: 0 auto;
    .dealers {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      width: 100%;
      background-color: #ffffff;
      box-sizing: border-box;
      .dealers_left {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 688px;
        height: 474px;
        background: #e0f5fc;
        border-radius: 10px;
        img {
          width: 546px;
          height: auto;
        }
        .title1 {
          position: absolute;
          left: 50%;
          top: -40px;
          transform: translate(-50%, 0);
          width: 578px;
          height: 64px;
          background: #00a0ac;
          border-radius: 10px;
          font-size: 30px;
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          text-align: justifyLeft;
          color: #ffffff;
          line-height: 64px;
          text-align: center;
        }
      }
      .dealers_center {
        margin-left: 40px;
        ul {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 100%;
          li {
            display: flex;
            align-items: center;
            div:nth-child(1) {
              width: 20px;
              height: 28px;
              background: #00a0ac;
            }
            div:nth-child(2) {
              width: 0;
              height: 0;
              border-top: 24px solid transparent;
              border-bottom: 24px solid transparent;
              border-left: 24px solid #00a0ac;
              border-right: 24px solid transparent;
            }
          }
        }
      }
      .dealers_right {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 620px;
        height: 474px;
        background: #e0f5fc;
        border-radius: 10px;
        img {
          width: 440px;
          height: auto;
        }
        .title1 {
          position: absolute;
          left: 50%;
          top: -40px;
          transform: translate(-50%, 0);
          width: 578px;
          height: 64px;
          background: #00a0ac;
          border-radius: 10px;
          font-size: 30px;
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          text-align: justifyLeft;
          color: #ffffff;
          line-height: 64px;
          text-align: center;
        }
      }
    }
  }
  .features {
    display: flex;
    justify-content: center;
    width: 100%;
    .content {
      width: 1440px;
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          background-color: #f5f5f5;
        }
        li:nth-child(odd) {
          width: 725px;
        }
        li:nth-child(even) {
          width: 715px;
        }
        li:nth-child(-n + 4) {
          height: 447px;
        }
        li:nth-child(1) {
          img {
            width: 725px;
            height: 492px;
          }
        }
        li:nth-child(4) {
          img {
            width: 715px;
            height: 447px;
          }
        }
        li:nth-child(5) {
          img {
            width: 725px;
            height: 575px;
          }
        }
        .li_text {
          padding: 60px 74px 40px;
          box-sizing: border-box;
          .li_text_title {
            display: flex;
            align-items: center;
            margin: 30px 0px 25px 0px;
            font-size: 24px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: left;
            color: #00a0ac;
            line-height: 30px;
            .li_text_title_icon1 {
              margin-left: 5px;
              width: 6px;
              height: 6px;
              background: #00a0ac;
            }
            .li_text_title_icon2 {
              width: 0;
              height: 0;
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-left: 6px solid #00a0ac;
              border-right: 6px solid transparent;
            }
          }
          .li_text_title_p {
            display: flex;
            margin: 20px 0px;
            font-size: 24px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: left;
            color: #00a0ac;
            line-height: 30px;
            p {
              font-size: 24px;
              font-family: Source Han Sans CN, Source Han Sans CN-Bold;
              font-weight: 700;
              text-align: left;
              color: #00a0ac;
              line-height: 30px;
            }
          }
          p {
            font-size: 28px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: left;
            color: #333334;
            line-height: 36px;
          }
          .item {
            margin-top: 10px;
            display: flex;
            .icon {
              margin-top: 8px;
              box-sizing: border-box;
              width: 0;
              height: 0;
              border-top: 8px solid transparent;
              border-right: 10px solid transparent;
              border-left: 16px solid #00a0ac;
              border-bottom: 10px solid transparent;
            }
            .text {
              width: 527px;
              p {
                font-size: 20px;
                font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                line-height: 30px;
              }
            }
          }

          .item_p {
            display: flex;
            width: 100%;
            .icon {
              margin-top: 8px;
              box-sizing: border-box;
              width: 0;
              height: 0;
              border-top: 8px solid transparent;
              border-right: 10px solid transparent;
              border-left: 16px solid #00a0ac;
              border-bottom: 10px solid transparent;
            }
            .text {
              width: auto;
              p {
                font-size: 20px;
                font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                line-height: 30px;
              }
            }
            .item_p_left {
              width: 318px;
              .item {
                .text {
                  display: flex;
                  p:nth-child(2) {
                    flex: 1;
                  }
                }
              }
            }
            .item_p_right {
              margin-left: 40px;
              flex: 1;
              .item {
                .text {
                  display: flex;
                  p:nth-child(2) {
                    flex: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .instructions {
    display: flex;
    justify-content: center;
    width: 100%;
    .content {
      width: 1440px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-bottom: 50px;
          margin-right: 20px;
          width: 344px;
          height: 350px;
          cursor: pointer;
          .video {
            position: relative;
            width: 344px;
            height: 208px;
            .icon {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 74px;
              height: 74px;
              border-radius: 50%;
              img {
                width: 74px;
                height: 74px;
              }
            }
            .bg_img {
              position: absolute;
              left: 0;
              top: 0;
              width: 344px;
              height: 208px;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.3) 0%,
                rgba(255, 255, 255, 0.3) 100%
              );
              border-radius: 5px 5px 0px 0px;
            }
          }
          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px 26px 27px 26px;
            width: 100%;
            height: calc(350px - 208px);
            background-color: #f5f5f5;
            box-sizing: border-box;
            .title {
              font-size: 24px;
              font-family: Source Han Sans CN, Source Han Sans CN-Regular;
              font-weight: 400;
              text-align: justifyLeft;
              color: #333333;
              line-height: 30px;
            }
            .time {
              font-size: 16px;
              font-family: Source Han Sans CN, Source Han Sans CN-Regular;
              font-weight: 400;
              text-align: justifyLeft;
              color: #333333;
              line-height: 40px;
            }
          }
        }
        li:nth-child(4) {
          margin-right: 0px !important;
        }
        li:nth-child(8) {
          margin-right: 0px !important;
        }
      }
    }
  }
  .active_tabs {
    font-weight: 700;
    border-bottom: 2px solid #00a0ac;
  }
}
</style>

<style scoped lang="scss">
// 移动端 样式
@import "./media";
</style>
